<template>
  <b-row>
    <b-col cols="12">
      <b-card class="my-card m-0" @scroll.passive="handleScroll">
        <b-table
          striped
          small
          id="myTables"
          bordered
          selectable
          selected-variant="primary"
          select-mode="single"
          responsive="sm"
          :items="displayedData"
          :fields="field"
          head-row-variant="secondary"
          v-hotkey="{ 'ctrl + m': performerMap }"
          @row-clicked="clicked"
          @row-contextmenu="handleContextMenuEvent"
        >
          <template #cell(checkbox)="row">
            <b-form-checkbox
              v-model="row.detailsShowing"
              plain
              class="vs-checkbox-con"
              @change="row.toggleDetails"
            >
            </b-form-checkbox>
          </template>
          <template #cell(performer_full_name)="data">
            <span @dblclick="getPerformer(data)" class="reference-field">
              {{ data.item.performer_full_name }}
            </span>
          </template>
          <template #cell(performer_transport_model)="data">
            <span @dblclick="getDriver(data)" class="reference-field">
              {{ data.item.performer_transport_model }}
            </span>
          </template>
          <template #cell(created_at)="data">
            {{ $moment(data.item.created_at).format("DD.MM.YYYY HH:mm") }}
          </template>
        </b-table>
        <vue-context ref="menu" class="menu">
          <li>
            <b-link class="d-flex align-items-center" @click="performerMap">
              <img :src="mapIcon" />
              <span class="menu_name_driver">Показать на карте</span>
              <span class="menu_keyup_map">Ctrl+M</span>
            </b-link>
            <hr />
            <b-link class="d-flex align-items-center" @click="autoUpdate">
              <img :src="iconChect" />
              <span class="menu_name_driver_afto">Автообновление</span>
            </b-link>
          </li>
        </vue-context>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
import VueContext from "vue-context";
import lazyLoading from "@/@core/mixins/utils/lazyLoading";
export default {
  mixins: [lazyLoading],
  components: {
    VueContext,
    FeatherIcon,
  },
  props: ["dataTable", "field"],
  data() {
    return {
      id: [],
      is_active: false,
      mapIcon: require("@/assets/images/icons/map.svg"),
      iconChect: require("@/assets/images/icons/square.svg"),
      iconsSquare: require("@/assets/images/icons/square.svg"),
      iconsCheck: require("@/assets/images/icons/checkSquareIcon.svg"),
    };
  },
  methods: {
    autoUpdate() {
      this.iconChect = this.is_active ? this.iconsSquare : this.iconsCheck;
      this.is_active = !this.is_active;
      this.$emit("timerAfto", this.is_active);
    },
    performerMap() {
      let performer = this.id;
      this.$router.push({
        name: "performer-map",
        query: { filter: performer, text: "" },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Водитель " + performer.performer_id,
        `/performer-map`,
        performer,
      ]);
    },
    getPerformer(data) {
      this.$router.push({
        name: "driver-performer",
        query: { filter: data.item.performer_id },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Водитель " + data.item.performer_id,
        `/driver-performer`,
        data.item.performer_id,
      ]);
    },
    getDriver(data) {
      this.$router.push({
        name: "car-performer",
        query: { filter: data.item.performer_transport_id },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Автомобиля " + data.item.performer_transport_id,
        `/car-performer`,
        data.item.performer_transport_id,
      ]);
    },
    clicked(item, index, event) {
      this.id = item;
      this.$store.state.filterPlus.tableIndex = index;
      const clickedElement = event.target;
      const clickedCell = clickedElement.closest("td");

      if (!clickedCell) {
        return;
      }

      const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(
        clickedCell
      );

      if (columnIndex < 0 || columnIndex >= this.field.length) {
        return;
      }

      const clickedField = this.field[columnIndex];
      this.$store.state.filterPlus.keyFilter = clickedField.key;
      this.$store.state.filterPlus.filterPlus = event.target.innerHTML.trim();
      cellSelect();
    },
    handleContextMenuEvent(item, index, event) {
      event.preventDefault();
      this.$refs.menu.open(event);
      this.id = item;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/table.scss";
.my-card .card-body {
  padding: 0px;
}
.my-card {
  overflow: auto;
  height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
.menu {
  width: 250px;
}
.menu_keyup_map {
  margin-left: 25px;
}
.menu_name_driver {
  margin-left: 25px;
}
.menu_name_driver_afto {
  margin-left: 9px;
}
</style>

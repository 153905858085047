<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter"
      style="height: 90vh">
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <table-car-state :dataTable="carState" @timerAfto="timerAfto" :field="field" />
    </div>
    <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
  </div>
</template>

<script>
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import tableCarState from "@/views/component/Table/tableCarState.vue";
export default {
  components: {
    tableCarState,
    ToastificationContent,
  },
  data() {
    return {
      showPreloader: false,
      carState: [],
      fields: [
        { key: "id", label: "ID" },
        { key: "division_name", label: "Подразделение" },
        { key: "performer_login", label: "Логин" },
        { key: "district", label: "Район" },
        { key: "performer_balance", label: "Баланс исполнителя" },
        { key: "perfomer_id", label: "ID исполнителя" },
        { key: "full_name_performer", label: "Ф.И.О" },
        { key: "performer_transport_model", label: "Модель исполнитель" },
        { key: "performer_transport_number", label: "Номер исполнитель" },
        { key: "performer_transport_id", label: "ID Заказа" },
        { key: "performer_lng", label: "Широта" },
        { key: "performer_lat", label: "Долгота" },
        { key: "date_time", label: "Дата" },
        { key: "state_status", label: "Статус" },
      ],
      field: [
        { key: "checkbox", label: "Флаг" },
        {
          key: "division",
          label: "Подразделение",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_login",
          label: "Логин",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_full_name",
          label: "Водитель",
          sortable: true,
          thStyle: { width: "200px" },
        },
        {
          key: "performer_transport_model",
          label: "Автомобиль",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_transport_number",
          label: "Номер авто",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "created_at",
          label: "Дата",
          sortable: true,
          thStyle: { width: "150px" },
        },
        //{ key: '', label: 'Время подразделения', sortable: true, thStyle: { width: '150px' } },
        {
          key: "state_status",
          label: "Статус",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "district",
          label: "Район",
          sortable: true,
          thStyle: { width: "250px" },
        },
        {
          key: "performer_lng",
          label: "Широта",
          sortable: true,
          thStyle: { width: "200px" },
        },
        {
          key: "performer_lat",
          label: "Долгота",
          sortable: true,
          thStyle: { width: "200px" },
        },
        // { key: '', label: 'Пользователь', sortable: true, thStyle: { width: '200px' } },
        //{ key: '', label: 'ID Заказа', sortable: true, thStyle: { width: '150px' } },
        // { key: '', label: 'Состаяние GPS', sortable: true, thStyle: { width: '150px' } },
        // { key: '', label: 'Актуалный', sortable: true, thStyle: { width: '150px' } },
      ],
    };
  },
  mounted() {
    this.$store.commit("pageData/setdataCount", null);
    this.openFilter();
  },
  methods: {
    openFilter() {
      let allRoutes = this.$store.state.draggableTab.tabs;
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      let arrayRoutes = [];
      allRoutes.forEach((element) => {
        arrayRoutes.push(element.path);
      });
      if (arrayRoutes.includes(this.$route.path) == true) {
        this.showPreloader = true;
        this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
          this.carState = res.data;
          this.$store.commit("pageData/setdataCount", this.carState.length);
          this.showPreloader = false;
          this.$store.commit("REFRESH_DATA", false);
          this.$store.commit("excel/SET_ORDERS", this.carState);
          this.$store.commit("excel/SET_COLUMNS", this.field);
        });
      } else if (arrayRoutes.includes(this.$route.path) == false) {
        this.$bvModal.show(this.$route.name + "filter");
      }
    },
    sendToParent(tableData) {
      this.carState = tableData;
      this.$store.commit("pageData/setdataCount", this.carState.length);
      this.$store.commit("excel/SET_ORDERS", this.carState);
      this.$store.commit("excel/SET_COLUMNS", this.field);
    },
    refresh() {
      this.showPreloader = true;
      this.$http
        .get("car-state")
        .then((res) => {
          this.carState = res.data;
          this.$store.commit("pageData/setdataCount", this.carState.length);
          this.showPreloader = false;
          this.$store.commit("REFRESH_DATA", false);
          this.$store.commit("excel/SET_ORDERS", this.carState);
          this.$store.commit("excel/SET_COLUMNS", this.field);
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Ошибка!",
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.errors,
            },
          });
        });
    },
    refreshIs_Active() {
      this.$http.get("car-state").then((res) => {
        this.carState = res.data;
      });
    },
    timerAfto(is_active) {
      is_active ? (this.timer = setInterval(() => this.refreshIs_Active(), 30000)) : clearInterval(this.timer);
    },
  },
  computed: {
    fetchingNewData() {
      return this.$store.state.refresh.fetchingNewData;
    },
  },
  watch: {
    fetchingNewData(val) {
      if (val) {
        let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
        this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
          this.carState = res.data;
          this.$store.commit("pageData/setdataCount", this.carState.length);
          this.$store.commit("REFRESH_DATA", false);
        });
      }
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.$store.commit("excel/SET_ORDERS", []);
      this.$store.commit("excel/SET_COLUMNS", []);
    }
  },
};
</script>
